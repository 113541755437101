.floaters {
  width: 100%;
  height: 110%;
  position: fixed;

}

.floatersSVG {
  /* width: 100%; */
  height: 100%;
}

#Item1 {
  fill-opacity: 1;
  -webkit-animation: slide-bl 22s linear infinite alternate both;
  animation: slide-bl 22s linear infinite alternate both;
}
#Item2 {
  -webkit-animation: slide-tl 26s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
    alternate both;
  animation: slide-tl 26s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
    alternate both;
}
#Item3 {
  -webkit-animation: slide-right 24s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite alternate both;
  animation: slide-right 24s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
    alternate both;
}

#Item5 {
  -webkit-animation: slide-bl 15s linear infinite alternate both;
  animation: slide-bl 15s linear infinite alternate both;
}
#Item4 {
  -webkit-animation: slide-tl 19s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
    alternate both;
  animation: slide-tl 19s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate
    both;
}
#Item6 {
  -webkit-animation: slide-right 20s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite alternate both;
  animation: slide-right 20s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
    alternate both;
}

#Item7 {
  -webkit-animation: slide-right 23s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite alternate both;
  animation: slide-right 23s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
    alternate both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-1-20 16:40:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-bl
 * ----------------------------------------
 */
@-webkit-keyframes slide-bl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(100px) translateX(-100px);
    transform: translateY(100px) translateX(-100px);
  }
}
@keyframes slide-bl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(100px) translateX(-100px);
    transform: translateY(100px) translateX(-100px);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-1-20 16:59:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-tl
 * ----------------------------------------
 */
@-webkit-keyframes slide-tl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(-100px) translateX(-100px);
    transform: translateY(-100px) translateX(-100px);
  }
}
@keyframes slide-tl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(-100px) translateX(-100px);
    transform: translateY(-100px) translateX(-100px);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-1-20 17:0:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}
