.mainpage {
  width: 60vw;
  margin-left: 35vw;
}

p {
  font-weight: 500;
}

@media only screen and (max-width: 1000px) {
  .mainpage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    width: 50vw;
    margin-left: 45vw;
    align-items: center;
  }
}

@media only screen and (max-width: 750px) {
  .mainpage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    width: 90vw;
    margin: auto;
    align-items: center;
  }
}

/* 
---------------------------------------------
HomeSection
---------------------------------------------
 */

.homepage {
  height: 90vh;
  margin-top: 5vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.hompage-title {
  font-size: 3rem;
  margin-top: 180px;
}

.hompage-textContainer {
  text-align: center;
  align-items: center;
}

.react-typewriter-text {
  text-align: center !important;
}

.homepage-iconContainer {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  height: 20%;
  margin-top: auto;
  margin-bottom: 60px;
  padding: 30px;
}

.homepage-iconItem {
  text-align: center;
  align-items: center;
  text-decoration: none;

}

.homepage-icon {
  width: 35px;
  height: 35px;
  margin: 10px;
}
.homepage-icon2 {
  width: 55px;
  height: 55px;
  padding: 20px;
  margin-top: 70px;
}


/* Bounce In */
.hvr-bounce-in {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-in:hover,
.hvr-bounce-in:focus,
.hvr-bounce-in:active {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

.sectionBreak {
  height: 250px;
}

.sectionBreakAnchor {
  height: 10px;
}

/* 
---------------------------------------------
AboutSection
---------------------------------------------
 */

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.aboutContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.intro {
  width: 350px;
  text-align: center;
  padding: 10px;
  margin-bottom: 40px;
}

.about-introText {
  font-size: 40px;
}

.about-section {
  /* height: 300px; */
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  filter: blur(0px);
  -webkit-filter: blur(0px);
}

.about-subTitleContainer {
  text-align: center;
  margin: 7px;
}

.about-subTitle {
  font-size: 26px;
  margin-bottom: 10px;
}

.aboutL {
  justify-content: start;
}
.aboutM {
  justify-content: center;
}
.aboutR {
  justify-content: flex-end;
}
.about2 {
  justify-content: space-around;
}
.about-boxwide {
  width: 360px;
  height: 250px;
  margin-top: 5px;
  margin: 9px;
}

.about-box {
  width: 300px;
  height: 250px;
  margin-top: 5px;
  margin: 9px;
}

.about-text {
  padding: 5px;
  margin: 5px;
  text-align: center;
  vertical-align: middle;
}
.about-textL {
  padding: 5px;
  margin: 5px;
  text-align: left;
  vertical-align: middle;
  height: 60%;
  display: flex;
  align-items: center;
}

.about-trinity {
  font-size: 20px;
  margin: 5px;
  color: white;
}

.about-course {
  margin: 3px;
  font-size: 22px;
}

.about-year {
  color: white;
}

.about-skillsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.about-skill {
  display: flex;
  text-align: center;
  justify-content: center;
  vertical-align: middle;
  width: 120px;
  height: 30px;
  line-height: 30px;
  margin: 5px;
}

.about-textUl {
  margin-top: 30px;
}

.about-textLi {
  margin: 10px;
  padding: 2px;
}

/* 
---------------------------------------------
PortfolioSection
---------------------------------------------
 */
.cardContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
a {
  text-decoration: none;
}

.cardBox {
  perspective: 600px;
  transition: all 1.3s ease 1s;
  margin: 40px;
}
.cardBox:hover .project-card {
  transform: rotateY(180deg);
}

.project-card {
  border-radius: 25px;
  width: 260px;
  height: 300px;
  transform-style: preserve-3d;
  transition: transform 0.4s ease 0s;
  -webkit-animation: giro 2s 1;
  animation: giro 2s 1;

  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.67);
  backdrop-filter: blur(-2px);
  -webkit-backdrop-filter: blur(-2px);
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.project-card .project-cardfront,
.project-card .project-cardback {
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  position: absolute;
  text-align: center;
}

.project-cardback {
  transform: rotateY(180deg);
  background-image: rgb(255, 255, 255);
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0.4) 20%,
    rgba(255, 255, 255, 0.15) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}

.project-cardImage {
  width: 260px;
  height: 180px;
  border-radius: 25px 25px 0px 0px;
}

.project-cardTitle {
  font-size: 22px;
  margin: 5px;
  margin-top: 10px;
}

.project-cardTags {
  display: flex;
}

.project-cardTag {
  display: flex;
  text-align: center;
  justify-content: center;
  vertical-align: middle;
  width: 90px;
  height: 25px;
  line-height: 25px;
  margin: 2px;
  font-size: 12px;

  box-shadow: 0 8px 32px 0 rgba(15, 15, 43, 0.479);
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.28);
}

.project-cardDesc {
  padding: 12px;
}

.project-cardfine {
  margin-top: 10px;
  font-size: small;
}

@-webkit-keyframes giro {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes giro {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

.chartContainer1 {
  height: 550px;
  width: 100%;
  overflow-x: scroll;
  display: flex;
  margin: 40px;
  flex-direction: column;
  align-items: center;
}

.chartTitle {
  margin: 35px;
  font-size: 25px;
  margin-bottom: 8px;
}

canvas {
  height: 100% !important;
}

.chartjs-render-monitor {
  height: 100% !important;
  width: 100% !important;
  color: white !important;
}



.contactContainer{
  text-align: center;
  padding: 30px;
}