.navbar{
position: fixed;
height: 90vh;
width: 270px;
top: 50%;
left: 6vw;
margin-top: -45vh; /* Negative half of height. */
align-items: center;
}

@media only screen and (max-width: 750px) {

    .navbar{
        position: relative;
        margin-top: 60px;
        left: 50%;
        width: 320px;
        margin-left: -160px;
    }
  }



.navbar-avatarContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 30px;
}

.navbar-avatarColour{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-avatar{
    width: 130px;
}
.navbar-header{
   text-align: center;
   padding-top: 10px;
}



.navbar-name{
    font-size: 32px;
    padding-bottom: 10px;
}

.navbar-desc{
    color: #f3f3f3;
    font-size: 20px;
}



.navbar-navContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 5px;
    align-items: center;
    justify-content: space-around;
    height: auto;

}

.navItem{
    height: 35px;
    width: 190px;
    margin: 5px;
    background: none;
    border: none;
    text-align: left;
    padding: 7px;
    padding-left: 25%;
    display: flex;
    align-items: center;
    text-decoration: none;
    border-radius: 15px;
    cursor: pointer;
}

.navItem-icon{
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.navItem-text{
    font-size: 16px;
}


.navItem:focus {
    outline: none !important;
    outline-offset: none !important;
}


.navItem::after {
    content: "";
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
    border-radius: 15px;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .navItem:hover {
    -webkit-transform: scale(1.25, 1.25);
    transform: scale(1.01, 1.01);
  }
  
  .navItem:hover::after {
      opacity: 1;
  }