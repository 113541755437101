* {
  margin: 0;
  padding: 0;
  color: white;
  text-shadow: 1px 1px 4px #000;
}


.white{
  font-size: larger;
  font-weight: 450;
  
}


.glass {
  background-image: rgb(255, 255, 255);
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0.4) 20%,
    rgba(255, 255, 255, 0.15) 100%
  );
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.28);
}




body{
  overflow: overlay;
}

/* width */
::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-image: rgb(255, 255, 255);
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0.3) 30%,
    rgba(255, 255, 255, 0.05) 100%
  );
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-image: rgb(255, 255, 255);
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.5) 30%,
    rgba(255, 255, 255, 0.3) 100%
  );
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}