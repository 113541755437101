*{
  font-family: Varela Round, 'Roboto', 'Fira Sans', 'Helvetica Neue',sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;

  /** background image */
  background-image: url("./Assets/Images/bg3.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 105% 100%;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
